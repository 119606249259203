import React, { useEffect } from "react";
import PlayerManager from "services/PlayersManager";

// הגדרת סוגי הפרופס
interface ShowVSProps {
  timerInSecond?: number;
  handlerEndCallback?: () => void; // פונקציה ללא פרמטרים שמחזירה void
}

export const ShowVS= ({
  timerInSecond = 2,
  handlerEndCallback = null,
}:ShowVSProps): React.JSX.Element => {
  useEffect(() => {
    const timer = setTimeout(() => {
      if (handlerEndCallback) {
        handlerEndCallback(); // הפעל את הקולבק לאחר הזמן שהוגדר
      }
    }, timerInSecond * 1000);

    return () => clearTimeout(timer); // נקה את ה-timer אם הקומפוננטה מוסרת
  }, [timerInSecond, handlerEndCallback]);

  const getPlayClassName = (index: number): string => {
    return `player p${index}`;
  };

  return (
    <div className="vs-container">
      {(PlayerManager.count() > 1) && <div className="vs">vs</div>}
      <div className="container-players">
        {PlayerManager.getPlayers().map((player, index) => (
          <div key={player.id} className={getPlayClassName(index)}>
            <div className="Avatar">{player.emoji}</div>
            <div className="player-name">{player.name}</div>
            {(player.win && player.win > 0) && <div className="player-points">{player.win} Wins</div>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ShowVS;
