import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import "./../TicTacToe.css";
import { ShowVS } from "components/gameStages/ShowVS";
import { ShowRound } from "components/gameStages/ShowRound";
import Leaderboard from "components/gameStages/Leaderboard";
import { MESSAGE_TYPES,SHOW_ROUND_TYPES, GAME_TICTACTOE_MOVE_TYPES, GAME_STATE_TYPES, TARGET_TYPES, GAME_MOVE_TYPES } from "types/gameTypes";
import playerManager, { Player } from "services/PlayersManager"; // מנהל המשתמשים
import TicTacToe from "../TicTocToeManager";

interface TicTacToeBoardProps {
  timerInteval: number;
  // gameMsg: any;
  sendBoardMsg: (target: TARGET_TYPES, msg: any) => void;
}

const TicTacToeBoard = forwardRef(
  ({ timerInteval = 15,  sendBoardMsg }: TicTacToeBoardProps, ref): JSX.Element => {  //gameMsg,
    const [stage, setStage] = useState(SHOW_ROUND_TYPES.VS);
    const [seconds, setSeconds] = useState(timerInteval);
    const [state, setState] = useState<"game" | "draw" | "win">("game");
    const ticTacToe = useMemo(() => new TicTacToe(), []);
    const [board, setBoard] = useState(ticTacToe.getBoard());

    let playerCurrectTurn = useRef<Player>(null);

    const restartGame = () => {
      playerManager?.setRandonTurns();
      playerCurrectTurn.current = playerManager?.getCurrentPlayerTurn();

      ticTacToe.resetBoard();
      setBoard(ticTacToe.getBoard());
      setState("game"); // reset state
      setStage(SHOW_ROUND_TYPES.VS);
      setSeconds(timerInteval);
      setBoard(ticTacToe.getBoard());
      sendBoardMsg(TARGET_TYPES.ALL, { msgType: GAME_MOVE_TYPES.STATE, state: GAME_STATE_TYPES.RESTART });
    };

    useEffect(() => {
      playerManager?.setRandonTurns();
      playerCurrectTurn.current = playerManager?.getCurrentPlayerTurn();
    }, []);

    const exitGame = () => {
      sendBoardMsg(TARGET_TYPES.ALL, { msgType: GAME_MOVE_TYPES.STATE, state: GAME_STATE_TYPES.EXIT });
      window.location.reload(); // מרענן את הדף
    };
    useEffect(() => {
      if (stage !== SHOW_ROUND_TYPES.LEVEL || state !== "game") return;
      // הפעלת interval שמעדכן את ה-state כל שניה
      if (seconds < 0) {
        ticTacToe.makeRandomMove();
        playerCurrectTurn.current = playerManager?.getCurrentPlayerTurn();
        sendBoardMsg(TARGET_TYPES.ALL, { msgType: GAME_TICTACTOE_MOVE_TYPES.BOARD, board: ticTacToe.getBoard() });
        sendBoardMsg(TARGET_TYPES.PRIVATE, {
          playerId: playerCurrectTurn.current.id,
          msgType: GAME_TICTACTOE_MOVE_TYPES.YOURTURN,
        });
        setSeconds(timerInteval);
        checkEndGame();
      }

      const interval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);

      // ניקוי ה-interval כאשר הרכיב מוסר מה-DOM
      return () => clearInterval(interval);
    }, [seconds, stage]); // [] מבטיח שהאפקט ירוץ רק פעם אחת כשמרכיבים את הרכיב

    const checkEndGame = () => {
      if (ticTacToe.checkWinner()) {
        setState("win");
        sendBoardMsg(TARGET_TYPES.ALL, { msgType: GAME_MOVE_TYPES.STATE, state: GAME_STATE_TYPES.GAMEOVER });
        return true;
      } else if (ticTacToe.checkDraw()) {
        setState("draw");
        sendBoardMsg(TARGET_TYPES.ALL, { msgType: GAME_MOVE_TYPES.STATE, state: GAME_STATE_TYPES.GAMEOVER });
        return true;
      }
      return false;
    };

    useImperativeHandle(ref, () => ({
      gameMessages(gameMessages: any) {
        if (!gameMessages) return;

        if (gameMessages.move !== null && gameMessages.move.restart === true) {
          restartGame();
          return;
        } else if (gameMessages.move !== null && gameMessages.move.restart === false) {
          exitGame();
          return;
        }

        if (gameMessages.move !== null && gameMessages.move.cell !== null) {
          if (gameMessages.playerId !== playerCurrectTurn.current.id) return;

          ticTacToe.makeMove(gameMessages.move.cell);
          setBoard(ticTacToe.getBoard());

          sendBoardMsg(TARGET_TYPES.ALL, { msgType: GAME_TICTACTOE_MOVE_TYPES.BOARD, board: ticTacToe.getBoard() });

          if (!checkEndGame()) {
            setSeconds(timerInteval);
            playerCurrectTurn.current = playerManager?.getCurrentPlayerTurn();
            sendBoardMsg(TARGET_TYPES.PRIVATE, {
              playerId: playerCurrectTurn.current.id,
              msgType: GAME_TICTACTOE_MOVE_TYPES.YOURTURN,
            });
          }
        }
      },
    }));

    const ShowRoundStage = () => {
      // toast(`🎉 show Next Round!`);

      if (stage === SHOW_ROUND_TYPES.VS) {
        setStage(SHOW_ROUND_TYPES.ROUND);
      } else if (stage === SHOW_ROUND_TYPES.ROUND) {
        setStage(SHOW_ROUND_TYPES.LEVEL);

        sendBoardMsg(TARGET_TYPES.ALL, { msgType: GAME_TICTACTOE_MOVE_TYPES.BOARD, board: ticTacToe.getBoard() });
        sendBoardMsg(TARGET_TYPES.PRIVATE, {
          playerId: playerCurrectTurn.current.id,
          msgType: GAME_TICTACTOE_MOVE_TYPES.YOURTURN,
        });
      }
      return;
    };

    const getStage = () => {
      if (stage === SHOW_ROUND_TYPES.VS) {
        return <ShowVS timerInSecond={3} handlerEndCallback={ShowRoundStage} />;
      } else if (stage === SHOW_ROUND_TYPES.SCORE) {
        return (
          <div className="tictactoe">
            <div className="tictactoe-container">
              <Leaderboard exitGame={exitGame} />
            </div>
          </div>
        );
      } else if (stage === SHOW_ROUND_TYPES.ROUND) {
        return (
          <div className="tictactoe">
            <div className="tictactoe-container">
              <ShowRound
                timerRoundInSecond={3}
                handlerEndCallback={ShowRoundStage}
                roundNumber={1}
                roundTitle="Get Ready!"
                roundSubTitle=""
              />
            </div>
          </div>
        );
      } else {
        return <div>Other Window, check the problem!</div>;
      }
    };

    return stage !== SHOW_ROUND_TYPES.LEVEL ? (
      getStage()
    ) : state === "game" ? (
      <div className="tictactoe">
        <div className="tictactoe-container">
          <div className="title">Chocolates VS Candies</div>
          <div className="board">
            {Object.keys(board).map((cell, cellIndex) => (
              <div className="cell" key={cellIndex}>
                {board[cell] !== "" ? (board[cell] === "X" ? "🍩" : "🍭") : ""}
              </div>
            ))}
          </div>

          <div className="progress progress-striped active">
            <div
              role="progressbar"
              style={{ width: `${(seconds / timerInteval) * 100}%`, backgroundColor: "#2196F3" }}
              className="progress-bar progress-bar-secondary"></div>
          </div>

          <div className="turnState">
            Turn : {playerCurrectTurn.current.name} {playerCurrectTurn.current.emoji}
          </div>
        </div>
      </div>
    ) : state === "draw" ? (
      <div className="tictactoe">
        <div className="tictactoe-container">
          <div className="result">
            😎
            <br />
            Draw !
          </div>
        </div>
      </div>
    ) : (
      <div className="tictactoe">
        <div className="tictactoe-container">
          <div className="result">
            {playerCurrectTurn.current.emoji}
            <br />
            {playerCurrectTurn.current.name}
            <br />
            🏆 Win !
          </div>
        </div>
      </div>
    );
  }
);

export default TicTacToeBoard;
