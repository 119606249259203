export enum TARGET_TYPES{
  PRIVATE = "private",
  ALL = "all",
  ADMIN = "admin",
}
export enum MESSAGE_TYPES{
  JOIN = "join",
  GAME = "game",
};

export enum GAME_MESSAGE_TYPES{
  MOVE = "move",
  SCORE = "score",
};

export enum GAMES_TYPES{
  TRIVIA = "Trivia",
  TICTACTOE = "TicTacToe",
};

export enum GAME_STATE_TYPES{
  LOGIN = "Login",
  WAITING = "Waiting",
  DENIED = "Denied",
  BUSY = "Busy",
  STARTING = "Starting",
  PLAYING = "Playing",
  GAMEOVER = "GameOver",
  RESTART = "Restart",
  EXIT = "Exit",
};

export enum GAME_MOVE_TYPES{
  SCORE  = "Score",
  STATE = "State",
  MOVE = "Move",
};

export enum SHOW_ROUND_TYPES{
  OPENING = "OPENING",
  VS = "VS",
  SCORE = "SCORE",
  ROUND = "ROUND",
  LEVEL = "LEVEL",
};

export enum GAME_TRIVIA_MOVE_TYPES{
  ANSWER = "Answer",
  QUESTION = "Question",
  TIMEREND = "TimerEnd",
};

export enum GAME_TICTACTOE_MOVE_TYPES{
  BOARD = "Board",
  WINNER = "Winner",
  LOSE = "Lose",
  DRAW = "Draw",
  YOURTURN = "YourTurn",
};



