import React, { useEffect } from "react";

// הגדרת ממשק לפרופס של הקומפוננטה
interface ShowRoundProps {
  timerRoundInSecond?: number; // פרופס אופציונלי עם ערך ברירת מחדל
  handlerEndCallback?: () => void; // פונקציה אופציונלית ללא פרמטרים שמחזירה void
  roundNumber: number; // מספר סיבוב (חובה)
  roundTitle: string; // כותרת הסיבוב (חובה)
  roundSubTitle: string; // כותרת משנה (חובה)
}

export const ShowRound = ({
  timerRoundInSecond = 2,
  handlerEndCallback,
  roundNumber,
  roundTitle,
  roundSubTitle,
}:ShowRoundProps): React.JSX.Element => {
  useEffect(() => {
    const timer = setTimeout(() => {
      if (handlerEndCallback) {
        handlerEndCallback(); // הפעל את הקולבק לאחר הזמן שהוגדר
      }
    }, timerRoundInSecond * 1000);

    return () => clearTimeout(timer); // נקה את ה-timer אם הקומפוננטה מוסרת
  }, [timerRoundInSecond, handlerEndCallback]);

  return (
    <div className="round-container">
      <div className="round-text">Round {roundNumber}</div>
      <div className="round-title">{roundTitle}</div>
      <div className="round-subtitle">{roundSubTitle}</div>
    </div>
  );
};

export default ShowRound;
