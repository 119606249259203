import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { toast } from "react-toastify";
import "./../TriviaQuestion.css";
import { ShowVS } from "components/gameStages/ShowVS";
import { ShowRound } from "components/gameStages/ShowRound";
import Leaderboard from "components/gameStages/Leaderboard";
import { TARGET_TYPES, SHOW_ROUND_TYPES, GAMES_TYPES, GAME_MOVE_TYPES, GAME_STATE_TYPES, MESSAGE_TYPES, GAME_TRIVIA_MOVE_TYPES } from "types/gameTypes";
import PlayerManager from "services/PlayersManager"; // מנהל המשתמשים
import useQuestions from "hooks/useQuestions";
interface TriviaQuestionProps {
  quest_id?: number;
  timerInteval: number;
  questionsCount?: number;
  sendTriviaMsg: (target: TARGET_TYPES, msg: any) => void;
}

const TriviaQuestion = forwardRef(({
  quest_id = -1,
  timerInteval = 10,
  questionsCount = 1,
  sendTriviaMsg,
}: TriviaQuestionProps,ref) : JSX.Element => {
  const buttonsEmojis = ["🟨", "🟩", "🟦", "🟪"];
  const [seconds, setSeconds] = useState(timerInteval);
  const [stage, setStage] = useState(SHOW_ROUND_TYPES.VS);
  const [questionData, setQuestionData] = useState(null);
  const [playersAnswers, setPlayersAnswers] = useState([]);
  const [playersHistoryAnswers, setPlayersHistoryAnswers] = useState([]);
  const [questionIndex, setQuestionIndex] = useState(0); // איזה שאלה כעת
  const [selectedAnswer, setSelectedAnswer] = useState(null); // תשובה שנבחרה
  const [correctAnswers, setCorrectAnswers] = useState([]); // אינדקס התשובה הנכונה
  const [reload_index, setReload_index] = useState(0); // אינדקס התשובה הנכונה
  const { questions, loading } = useQuestions({ questionsCount, reload_index });

  const restartGame = () => {
    sendTriviaMsg(TARGET_TYPES.ALL,{ msgType: GAME_MOVE_TYPES.STATE, state: GAME_STATE_TYPES.RESTART });
    setQuestionData(null);
    setSeconds(timerInteval);
    setReload_index((prev) => prev + 1);
    setStage(SHOW_ROUND_TYPES.VS);
    setSelectedAnswer(null);
    setQuestionIndex(0);
    setPlayersAnswers([]);
    setCorrectAnswers([]);
    PlayerManager?.resetPoints();
    setPlayersHistoryAnswers([]);
  };

  const exitGame = () => {
    sendTriviaMsg(TARGET_TYPES.ALL,{ msgType: GAME_MOVE_TYPES.STATE, state: GAME_STATE_TYPES.EXIT });
    window.location.reload(); // מרענן את הדף
  };

  useImperativeHandle(ref, () => ({
    gameMessages(gameMessages: any) {
        if (gameMessages.move?.restart === true) {
      return restartGame();
    } else if (gameMessages.move?.restart === false) {
      return exitGame();
    }

    if (gameMessages.move?.answer === undefined) return;
    const answer = gameMessages.move?.answer;

    if (playersAnswers.find((player) => player.playerId === gameMessages.playerId) === undefined) {
      playersAnswers.push({ playerId: gameMessages.playerId, answer: answer, seconds: seconds });
      const player = PlayerManager.getPlayer(gameMessages.playerId);
      setPlayersHistoryAnswers([...playersHistoryAnswers, { gameMessages, roundNumber: questionIndex }]);
      if (player) {
        toast(`${player.emoji} ${player.name} is Answered !`);
      }
    }
    console.log("New data received! " + JSON.stringify(gameMessages));
    }}));

  useEffect(() => {
    if (stage === SHOW_ROUND_TYPES.LEVEL && questions.length > 0) {
      // toast(`🎉 show Next Question!`);
      showNextQuestion();
    }
  }, [questions, stage]);

  const calcScore = () => {
    if (playersAnswers) {
      playersAnswers.forEach((voing) => {
        const player = PlayerManager.getPlayer(voing.playerId);
        if (player) {
          if (voing.answer === correctAnswers[0]) {

            player.score += 500 + voing.seconds * 10;
            const msg = {
              msgType: GAME_MOVE_TYPES.SCORE,
              playerId: player.id,
              score: player.score,
            };
            sendTriviaMsg(TARGET_TYPES.PRIVATE,msg); 
          }
        }
      });
    }
    setPlayersAnswers([]);
  };

  // שימוש ב-useEffect כדי להפעיל טיימר בעת טעינת הרכיב
  useEffect(() => {
    if (stage !== SHOW_ROUND_TYPES.LEVEL) return;
    // הפעלת interval שמעדכן את ה-state כל שניה
    if (seconds < 0) {
      if (selectedAnswer === "show") {
        if (questionIndex >= questionsCount) {
          setStage(SHOW_ROUND_TYPES.SCORE);
          const msg = { msgType: GAME_MOVE_TYPES.STATE, state: GAME_STATE_TYPES.GAMEOVER };
          sendTriviaMsg(TARGET_TYPES.ALL,msg);
          calcScore();
          return;
        }
        showNextQuestion();
      } else {
        const msg = { gameType: GAMES_TYPES.TRIVIA, msgType: GAME_MOVE_TYPES.STATE, state: GAME_TRIVIA_MOVE_TYPES.TIMEREND };
        sendTriviaMsg(TARGET_TYPES.ALL,msg);
        calcScore();
        showRightAnswer();
        return;
      }
    }

    const interval = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds - 1);
    }, 1000);

    // ניקוי ה-interval כאשר הרכיב מוסר מה-DOM
    return () => clearInterval(interval);
  }, [seconds, stage]); // [] מבטיח שהאפקט ירוץ רק פעם אחת כשמרכיבים את הרכיב

  useEffect(() => {
    if (questionData === null) return;

    setSelectedAnswer(null);
    setCorrectAnswers(questionData?.correct_answers);

    if (sendTriviaMsg != null) {
      const msg = { gameType: GAMES_TYPES.TRIVIA, msgType: "Question", question_data: questionData };
      sendTriviaMsg(TARGET_TYPES.ALL,msg);
    }

    setQuestionIndex(questionIndex + 1);
    setSeconds(timerInteval);
  }, [questionData]);

  const showNextQuestion = () => {
    setQuestionData(questions[questionIndex]);
  };

  const showRightAnswer = () => {
    setSelectedAnswer("show"); // מפעיל מצב של הצגת תשובה נכונה
    setSeconds(3);
    console.log("showRightAnswer");
  };

  const getButtonClass = (index) => {
    if (selectedAnswer === "show") {
      if (correctAnswers?.includes(index)) {
        return "answer correct animate-pulse";
      }
      return "answer incorrect animate-shake";
    }
    return "answer";
  };

  const ShowRoundStage = () => {
    // toast(`🎉 show Next Round!`);
    // ShowRound(3, handlerCallback, 1, "Easy Questions", "500 🪙 for right answer!")
    if (stage === SHOW_ROUND_TYPES.VS) {
      setStage(SHOW_ROUND_TYPES.ROUND);
    } else if (stage === SHOW_ROUND_TYPES.ROUND) {
      setStage(SHOW_ROUND_TYPES.LEVEL);
    }
    return;
  };

  const getStage = () => {
    if (stage === SHOW_ROUND_TYPES.VS) {
      return <ShowVS timerInSecond={3} handlerEndCallback={ShowRoundStage} />;
    } else if (stage === SHOW_ROUND_TYPES.SCORE) {
      return (
        <div className="trivia">
          <div className="trivia-container">
            <Leaderboard exitGame={exitGame} />
          </div>
        </div>
      );
    } else if (stage === SHOW_ROUND_TYPES.ROUND) {
      return (
        <div className="trivia">
          <div className="trivia-container">
            <ShowRound
              timerRoundInSecond={5}
              handlerEndCallback={ShowRoundStage}
              roundNumber={1}
              roundTitle="Easy Questions"
              roundSubTitle="500 🪙 for right answer!"
            />
          </div>
        </div>
      );
    } else {
      return <div>Other Window, check the problem!</div>;
    }
  };

  return stage !== SHOW_ROUND_TYPES.LEVEL ? (
    getStage()
  ) : !loading ? (
    <div className="trivia">
      <div className="trivia-container">
        <div className="questionCounter">{questionIndex + " / " + questionsCount}</div>
        <div className="question">{questionData?.question_text}</div>
        <div className="answers">
          {questionData?.options?.map((answer, index) => (
            <button key={index} className={getButtonClass(index)}>
              <span className="answer-index">{buttonsEmojis[index]}</span>
              {answer}
            </button>
          ))}
        </div>
        <div className="progress progress-striped active">
          <div
            role="progressbar"
            style={{ width: `${(seconds / timerInteval) * 100}%`, backgroundColor: "#2196F3" }}
            className="progress-bar progress-bar-secondary"></div>
        </div>
      </div>
    </div>
  ) : (
    <div>
      Question Not Loading :( <br /> Please wait ...
    </div>
  );
});

export default TriviaQuestion;
