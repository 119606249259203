import {  useState } from 'react';
import logo from './assests/app/logo.svg';
import './assests/style/App.css';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import PageNotFound from './pages/404Page';
import PlayerController from 'pages/player_controller';  // תיקון ייבוא
import GameBoard from 'components/gameBoard/GameBoard';

// Home component
function Home() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" width={500} alt="logo" />
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://antibug.co.il/question"
          target="_self"
          rel="noopener noreferrer"
        >
          Go Trivia
        </a>
      </header>
    </div>
  );
}

function App() {
  const [token, setToken] = useState<string | null>(null);  // טיפוס משתנה token

  async function fetchToken() {
    let storedToken = localStorage.getItem('Token');
  
    if (!storedToken) {
      try {
        const response = await fetch('/api/users/token');
  
        // Check if the response is successful (status code 200-299)
        if (!response.ok) {
          throw new Error(`Server error: ${response.status}`);
        }
  
        // Try parsing the response as JSON
        const data = await response.json();  // יש להוסיף await
        storedToken = data.token;
        localStorage.setItem('Token', storedToken);
      } catch (error) {
        console.error('Failed to fetch token:', error);
        return; // Optionally, handle the error in your app
      }
    }
  
    // Use the token as needed
    setToken(storedToken);
  }

  // useEffect(() => {
  //   fetchToken();
  // }, []); // [] כדי להבטיח שהפונקציה תתבצע רק בפעם הראשונה

  const router = createBrowserRouter(
    [
      {
        path: '/',
        element: <Home />,
      },
      {
        path: '/gameboard',
        element: <GameBoard />,
      },
      {
        path: '/player',
        element: <PlayerController  />,
      },
      {
        path: '*',
        element: <PageNotFound />,
      },
    ],
    {
      future: {
        v7_startTransition: true, // הפעלת הדגל
      },
    }
  );

  return <RouterProvider router={router} />;
}

export default App;
