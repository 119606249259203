import React, { useState, useEffect, useCallback, useImperativeHandle, forwardRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, CircularProgress } from "@mui/material";
import "./../TriviaQuestion.css";
import "components/ProgressBar/progressBar.scss";
import {
  MESSAGE_TYPES,
  GAME_MESSAGE_TYPES,
  GAME_TRIVIA_MOVE_TYPES,
  GAME_STATE_TYPES,
  GAME_MOVE_TYPES,
} from "types/gameTypes";
import { Question } from "types/triviaGameTypes";
import Odometer from "react-odometerjs";
// import 'odometer/themes/odometer-theme-car.css'
import "odometer/themes/odometer-theme-digital.css";
// import { sendMsg } from "services/SubPubProxy";

// Props Interface
interface TriviaRemoteProps {
  //gameMessages: any; // Replace `any` with a proper type for game messages
  timerInterval?: number;
  sendMove: (move, type: GAME_MESSAGE_TYPES | MESSAGE_TYPES) => void;
  onGameOver?: () => void;
  admin: boolean;
}

// Component
const TriviaRemote = forwardRef(
  ({ timerInterval, sendMove, onGameOver, admin }: TriviaRemoteProps, ref): JSX.Element => {
    const buttonsEmojis = ["🟨", "🟩", "🟦", "🟪"];
    const [questionData, setQuestionData] = useState<Question | null>(null);
    const [seconds, setSeconds] = useState(timerInterval);
    const [selectedAnswer, setSelectedAnswer] = useState<number | null>(null);
    const [showRightAnswer, setShowRightAnswer] = useState(false);
    const [isGameOver, setIsGameOver] = useState(false);
    const [myScore, setMyScore] = useState(0);

    // Interfaces for game data
    // Submit Answer Handler
    const handleAnswerSubmit = useCallback(
      (index: number) => {
        const _handleSubmitAnswer = (index) => {
          if (showRightAnswer) return;

          setSelectedAnswer(index);
          const sendmsg = {
            answer: index,
          };
          sendMove(sendmsg, MESSAGE_TYPES.GAME);
        };

        if (showRightAnswer) return;

        setSelectedAnswer(index);
        _handleSubmitAnswer(index);
      },
      [sendMove, showRightAnswer]
    );

    // Timer Logic
    useEffect(() => {
      const interval = setInterval(() => {
        setSeconds((prev) => (prev > 0 ? prev - 1 : prev));
      }, 1000);

      if (seconds === 0) {
        clearInterval(interval);
      }

      return () => clearInterval(interval);
    }, [seconds]);

    useImperativeHandle(ref, () => ({
      gameMessages(gameMessages: Record<string, unknown>) {
        if (!gameMessages) return;

        try {
          let { msgType, state, } = gameMessages;

          if (msgType === GAME_MOVE_TYPES.STATE) {
            if (state === GAME_STATE_TYPES.GAMEOVER) {
              setIsGameOver(true);
              onGameOver();
            } else if (state === GAME_TRIVIA_MOVE_TYPES.TIMEREND) {
              setShowRightAnswer(true);
            } else if (state === GAME_STATE_TYPES.RESTART) {
              setQuestionData(null);
              setMyScore(0);
              setIsGameOver(false);
            } else if (state === GAME_STATE_TYPES.EXIT) {
              window.location.reload();
            }
          } else if (msgType === GAME_MOVE_TYPES.SCORE) {
            let score = null;
            score = gameMessages.score;
            setMyScore(score);
          } else if (msgType === GAME_TRIVIA_MOVE_TYPES.QUESTION) {
            let question_data = null;
            question_data = gameMessages.question_data;
            
            setShowRightAnswer(false);
            setQuestionData(question_data);
            setSeconds(timerInterval); // Reset timer for the new question
          }
        } catch (error) {
          console.error("Error processing game message:", error);
        }
      },
    }));

    // // Game Messages Handler
    // useEffect(() => {
    //   if (!gameMessages) return;

    //   try {
    //   } catch (error) {
    //     console.error("Error processing game message:", error);
    //   }
    // }, [gameMessages, timerInterval, onGameOver]);

    // Reset on new question
    useEffect(() => {
      setSelectedAnswer(null);
      if ("vibrate" in navigator) {
        navigator.vibrate(150);
      }
    }, [questionData]);

    // Button Class Generator
    const getButtonClass = (index: number) => {
      if (showRightAnswer) {
        if (questionData?.correct_answers.includes(index)) {
          return `answer correct animate-pulse${selectedAnswer === index ? " selected" : ""}`;
        }
        return `answer incorrect animate-shake${selectedAnswer === index ? " selected" : ""}`;
      }
      return `answer${selectedAnswer === index ? " selected" : ""}`;
    };

    const restartGame = () => {
      sendMove({ restart: true }, MESSAGE_TYPES.GAME);
      setIsGameOver(false);
      setQuestionData(null);
    };

    const exitGame = (admin = false) => {
      // if(admin) return //TODO
      if (admin) {
        sendMove({ restart: false }, MESSAGE_TYPES.GAME);
      }
      window.location.href = window.location.href.toString();
    };

    const QuestionDisplay = () =>
      questionData ? (
        <div>
          <div className="question">
            <div className="client-question-text">{questionData.question_text}</div>
          </div>
          <div className="answers" style={{ display: "flex", flexDirection: "column" }}>
            {questionData.options.map((option, index) => (
              <Button
                key={index}
                className={getButtonClass(index)}
                disabled={selectedAnswer !== null}
                onClick={() => handleAnswerSubmit(index)}>
                <span className="answer-index">{buttonsEmojis[index]}</span>
                {option}
              </Button>
            ))}
          </div>
        </div>
      ) : null;

    const GameOverPlayerDisplay = () => (
      <div className="game-over">
        Game Over!
        <br />
        Want replay?
        <br />
        Yass! , Tell Admin to restart the game!
        <br />
        No? , it's o.k. : <Button onClick={() => exitGame()}>No</Button>
      </div>
    );

    const GameOverAdminDisplay = () => (
      <div className="game-over">
        Game Over!
        <br />
        Want replay?
        <br />
        <br />
        <Button variant="contained" onClick={() => restartGame()}>
          Yass!
        </Button>{" "}
        <Button onClick={() => exitGame(admin)}>No</Button>
      </div>
    );

    const WaitingDisplay = () => (
      <div className="waiting">
        <CircularProgress size={50} />
        <br />
        <h4>Get Ready!</h4>
      </div>
    );

    // Main Render
    return (
      <div className="trivia">
        <div className="trivia-score">
          <div>
            Score:{" "}
            <Odometer className="odometer" value={myScore} format="(,ddd),dd" animation="count" theme="digital" />
          </div>
        </div>
        <div className="trivia-container">
          {!isGameOver ? (
            questionData ? (
              <QuestionDisplay />
            ) : (
              <WaitingDisplay />
            )
          ) : admin ? (
            <GameOverAdminDisplay />
          ) : (
            <GameOverPlayerDisplay />
          )}
        </div>
      </div>
    );
  }
);

export default TriviaRemote;
