interface Board {
  [key: string]: string;
}

class TicTacToe {
  private board: Board;
  private currentPlayer: string;

  public on_winner: (player: string) => void;
  public on_draw: (player: string) => void;

  constructor() {
    this.board = {
      0: "",
      1: "",
      2: "",
      3: "",
      4: "",
      5: "",
      6: "",
      7: "",
      8: "",
    };
    this.currentPlayer = "X";
  }

  public makeRandomMove(): void {
    const availablePositions = Object.keys(this.board).filter(
      (key) => this.board[key] === ""
    );
    const randomIndex = Math.floor(Math.random() * availablePositions.length);
    const randomPosition = availablePositions[randomIndex];
    this.board[randomPosition] = this.currentPlayer;
    this.currentPlayer = this.currentPlayer === "X" ? "O" : "X";
  }

  public makeMove(position: number): void {
    if (this.board[position] !== "") {
      throw new Error("Position already occupied");
    }
    this.board[position] = this.currentPlayer;
    this.currentPlayer = this.currentPlayer === "X" ? "O" : "X";
  }

  public getBoard(): Board {
    return this.board;
  }

  public checkWinner(): string | null {
    const winningCombinations = [
      [0, 1, 2],
      [3, 4, 5],
      [6, 7, 8],
      [0, 3, 6],
      [1, 4, 7],
      [2, 5, 8],
      [0, 4, 8],
      [2, 4, 6],
    ];
    for (const combination of winningCombinations) {
      if (
        this.board[combination[0]] === this.board[combination[1]] &&
        this.board[combination[1]] === this.board[combination[2]] &&
        this.board[combination[0]] !== ""
      ) {
        return this.board[combination[0]];
      }
    }
    return null;
  }

  public checkDraw(): boolean {
    return Object.values(this.board).every((cell) => cell !== "");
  }

  public resetBoard(): void {
    this.board = {
      0: "",
      1: "",
      2: "",
      3: "",
      4: "",
      5: "",
      6: "",
      7: "",
      8: "",
    };
    this.currentPlayer = "X";
  }
}

export default TicTacToe;
