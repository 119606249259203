// hooks/useGameManager.ts
import { useState, useEffect, useRef } from "react";
import { TARGET_TYPES, GAMES_TYPES, GAME_STATE_TYPES, MESSAGE_TYPES } from "../types/gameTypes";
import { toast } from "react-toastify";
import { getEMQXManager } from "../services/EMQXBoardManager"; // חיבור הגלובלי
import PlayerManager, { Player } from "../services/PlayersManager"; // מנהל המשתמשים

const useGameManager = (
  boardId: string,
  gameType: GAMES_TYPES,
  maxPlayers: number,
  minPlayers: number,
  sendGameData: (data: any) => void
) => {
  const [gameState, setGameState] = useState(GAME_STATE_TYPES.WAITING);

  const adminIDRef = useRef<string | null>(null);
  const [adminID, setAdminID] = useState<string | null>(null);
  const [playerCounter, setPlayerCounter] = useState<number>(0);

  const emqxManagerRef = useRef<any>(null); // שימוש בחיבור הגלובלי

  useEffect(() => {
    const handleIncomingMessage = (topic: string, message: string) => {
      //toast(`📨 ${message} , topic ${topic}`);
      const msg = JSON.parse(message);
      const playerId = msg.playerId;
      if (!playerId) return;

      if (msg.type === MESSAGE_TYPES.JOIN) {
        handleJoinMessage(msg, playerId);
      } else if (msg.type === MESSAGE_TYPES.GAME) {
        handleGameMessage(msg, playerId);
      }
    };

    const handleJoinMessage = (msg: any, playerId: string) => {
      const _exmqx = emqxManagerRef.current;

      if (
        gameState === GAME_STATE_TYPES.STARTING ||
        gameState === GAME_STATE_TYPES.GAMEOVER ||
        PlayerManager.count() >= maxPlayers
      ) {
        const txt =
          gameState === GAME_STATE_TYPES.GAMEOVER
            ? "the game is already finished."
            : gameState === GAME_STATE_TYPES.STARTING
            ? "the room is already started."
            : PlayerManager.count() >= maxPlayers
            ? "the room is full."
            : "";

        toast(`🚫 ${playerId} Denied.`);
        _exmqx?.publish(`game_board/${boardId}/${playerId}`, {
          type: MESSAGE_TYPES.JOIN,
          response: "denied",
          message: txt,
        });
        return;
      }

      if (!PlayerManager.playerExists(playerId)) {
        const playerName = msg.playerName;

        if (!adminIDRef.current) {
          setAdminID(playerId);
          adminIDRef.current = playerId;

          toast(`${msg.emoji} ${playerName} joined as admin!`);
        } else {
          toast(`${msg.emoji} ${playerName} joined!`);
        }

        const newPlayer: Player = {
          id: playerId,
          name: msg.playerName,
          connected: true,
          emoji: msg.emoji,
          score: 0,
        };

        PlayerManager.addPlayer(newPlayer);

        setPlayerCounter(PlayerManager.count());
        _exmqx?.publish(`game_board/${boardId}/${playerId}`, {
          type: MESSAGE_TYPES.JOIN,
          admin: adminIDRef.current === playerId,
          gameType: gameType,
          response: "accepted",
        });
      }
    };

    const handleGameMessage = (msg, playerId) => {
      if (playerId === adminIDRef.current) {
        if (msg.move === GAME_STATE_TYPES.STARTING) {
          if (PlayerManager.count() < minPlayers) {
            toast(`🚫 Not enough players!`);
            return;
          }

          setGameState(GAME_STATE_TYPES.STARTING);
          return;
        }
      }
      if (sendGameData) sendGameData(msg);
    };

    if (boardId) {
      // משתמשים בחיבור הגלובלי
      const manager = getEMQXManager(boardId, handleIncomingMessage);
      manager.connect();
      emqxManagerRef.current = manager;
    }

    return () => {
      if (emqxManagerRef.current) {
        emqxManagerRef.current.disconnect();
      }
    };
  }, [boardId, gameState, maxPlayers, minPlayers]);

  useEffect(() => {
    adminIDRef.current = adminID;
  }, [adminID]);

  const sendGameMsg2Plyers = (target: TARGET_TYPES = TARGET_TYPES.ALL, gameData: any) => {
    const _exmqx = emqxManagerRef.current;
    if (gameData) {
      if (target === TARGET_TYPES.ALL) {
        _exmqx?.publish(`game_board/${boardId}/all`, { type: "game", gameData });
      } else {
        _exmqx?.publish(`game_board/${boardId}/${gameData.playerId}`, { type: "game", gameData });
      }
    }
  };

  useEffect(() => {
    if (gameState === GAME_STATE_TYPES.STARTING || gameState === GAME_STATE_TYPES.GAMEOVER) {
      const _emqx = emqxManagerRef.current;
      _emqx?.publish(`game_board/${boardId}/all`, { type: MESSAGE_TYPES.GAME, state: gameState });
      toast(`👾 Game ${gameState}!`);
    }
  }, [boardId, gameState]);

  return { gameState, playerCounter, sendGameMsg2Plyers };
};

export default useGameManager;
