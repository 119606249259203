import React, { useEffect, useState } from "react";
import AvatarSelector from "./avatarSelector";

const allowedEmoji = [
  "😊",
  "🙃",
  "🤪",
  "🤓",
  "🥸",
  "🚀",
  "🧟‍♂️",
  "👩‍💻",
  "💂‍♂️",
  "🧞‍♀️",
  "👩‍🦰",
  "👧",
  "👱‍♀️",
  "🧙‍♂️",
  "🧑‍🎄",
  "🧔🏽‍♀️",
  "🧒🏽",
  "🤶🏼",
  "👨🏼‍🎨",
  "⚽",
  "🎃",
  "🪩",
  "🃏",
  "🏍️",
  "🍺",
  "🦋",
  "🦄",
  "🐮",
  "🦁",
  "🐯",
  "🧌",
  "❤️‍🔥",
  "🤠",
  "🥶",
  "😶‍🌫️",
  "🧐",
  "🤑",
  "🪬",
  "👀",
  "😈",
  "😴",
  "💩",
  "👻",
  "👽",
  "🤖",
  "👾",
  "🙊",
  "😼",
  "💀",
  "🥷",
  "💃🏻",
  "🧛‍♂️",
  "👨‍🚀",
  "🤹‍♀️",
  "👩‍⚕️",
  "🐭",
  "🦝",
  "🦕",
  "🐶",
  "🐺",
  "🦹🏻‍♀️",
  "🦖",
  "👵🏻",
  "👩🏻‍✈️",
  "🐉",
  "🦭",
  "👴🏻",
  "🐧",
  "🐞",
  "🦞",
  "🌚",
  "🌞",
  "🪅",
];

interface PlayerAvatarProps {
  emoji?: string;
  setEmoji: (emoji: string) => void;
}

const getRandomEmoji = (): string => {
  const randomEmoji = allowedEmoji[Math.floor(Math.random() * allowedEmoji.length)];
  return randomEmoji;
};

const PlayerAvatar = ({ emoji, setEmoji }: PlayerAvatarProps): React.JSX.Element => {
  const [open, setOpen] = React.useState(false);
  const [playerAvatar, setPlayerAvatar] = useState(null);
  const [count, setCount] = useState(0);

  const setRandEmoji = () => {
    const rndAvatar = getRandomEmoji();
    setPlayerAvatar(rndAvatar);
    setEmoji(rndAvatar);
  };

  useEffect(() => {
    if (count < 4) {
      if (count === 0) {
        setRandEmoji();
      }
      const timer = setTimeout(() => {
        setRandEmoji();
        console.log(`useEffect executed after delay. Count: ${count + 1}`);
        setCount((prev) => prev + 1);
      }, 150); // 1000ms delay

      return () => clearTimeout(timer); // Cleanup the timer
    }
  }, [count, setEmoji]); // Dependency array includes count, causing it to run on count change.

  return (
    <div>
      <AvatarSelector
        open={open}
        emojis={allowedEmoji}
        onSelect={(semoji) => {
          setPlayerAvatar(semoji);
          setEmoji(semoji);
          setOpen(false);
        }}
        onClose={() => setOpen(false)}
      />
      <div className="myAvatar" onClick={() => setOpen(true)}>
        <span className="AvatarClient">{playerAvatar}</span>
        <span className="edit-emoji">✏️</span>
      </div>
    </div>
  );
};

export default PlayerAvatar;
