import React, { useState, useEffect, useCallback, useImperativeHandle, forwardRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, CircularProgress } from "@mui/material";
import "../TicTacToe.css";
import "components/ProgressBar/progressBar.scss";
import {
  MESSAGE_TYPES,
  GAME_MESSAGE_TYPES,
  GAME_TICTACTOE_MOVE_TYPES,
  GAME_STATE_TYPES,
  GAME_MOVE_TYPES,
} from "types/gameTypes";

// Props Interface
interface TicTacToeRemoteProps {
  //gameMessages: any; // Replace `any` with a proper type for game messages
  timerInterval?: number;
  sendMove: (move, type: GAME_MESSAGE_TYPES | MESSAGE_TYPES) => void;
  onGameOver?: () => void;
  admin: boolean;
}

// Component
const TicTacToeRemote = forwardRef(({  // gameMessages,
  timerInterval,
  sendMove,
  onGameOver,
  admin,
}: TicTacToeRemoteProps,ref): JSX.Element => {
  // const [seconds, setSeconds] = useState(timerInterval);
  const [isGameOver, setIsGameOver] = useState(false);
  const [isYourTurn, setIsYourTurn] = useState(false);
  const [boardData, setBoardData] = useState(null);
  const boardRef = React.useRef(boardData);

  // Expose sendMessage functionality via ref
  useImperativeHandle(ref, () => ({
    gameMessages(gameMessages: Record<string, unknown>) {
        if (!gameMessages) return;

        try {
          const { msgType, state } = gameMessages;
    
          if (msgType === GAME_MOVE_TYPES.STATE) {
            if (state === GAME_STATE_TYPES.GAMEOVER) {
              setIsGameOver(true);
              if (onGameOver) onGameOver();
            } else if (state === GAME_STATE_TYPES.RESTART) {
              setIsYourTurn(false);
              setBoardData(null);
              setIsGameOver(false);
            } else if (state === GAME_STATE_TYPES.EXIT) {
              window.location.reload();
            }
          } else if (msgType === GAME_TICTACTOE_MOVE_TYPES.BOARD) {
            setBoardData(gameMessages.board); // Reset timer for the new question
            boardRef.current = gameMessages.board;
            setIsYourTurn(false);
          } else if (msgType === GAME_TICTACTOE_MOVE_TYPES.YOURTURN) {
            setIsYourTurn(true);
          }
        } catch (error) {
          console.error("Error processing game message:", error);
        }
    },
  }));

  // Reset on new question
  useEffect(() => {
    if ("vibrate" in navigator) {
      navigator.vibrate(150);
    }
  }, [isYourTurn]);

  // Button Class Generator
  const getButtonClass = (index: number) => {
    if (boardData[index] !== null) {
      if (boardData[index] !== "X") {
        return `cell xCell"}`;
      } else {
        return `cell yCell"}`;
      }
    }
  };

  const restartGame = () => {
    sendMove({ restart: true }, MESSAGE_TYPES.GAME);
    setIsGameOver(false);
  };

  const exitGame = (admin = false) => {
    // if(admin) return //TODO
    if (admin) {
      sendMove({ restart: false }, MESSAGE_TYPES.GAME);
    }
    window.location.href = window.location.href.toString();
  };

  const handleSelectionSubmit = useCallback(
    (index: number) => {
      if (!isYourTurn) return;

      const _handleSubmitAnswer = (index: number) => {
        const sendmsg = {
          cell: index,
        };
        console.log(boardData);
        sendMove(sendmsg, MESSAGE_TYPES.GAME);
      };
      _handleSubmitAnswer(index);
    },
    [sendMove, boardData, isYourTurn]
  );

  const BoardDisplay = () =>
    boardData ? (
      <div>
        <div className="board">
          {Object.keys(boardData).map((option, index) =>
            boardData[index] === "" ? (
              <Button
                key={index}
                className={getButtonClass(index)}
                //   disabled={boardData[index] !== ""}
                onClick={() => handleSelectionSubmit(index)}>
                {" "}
              </Button>
            ) : (
              <div className="cell">
                {boardRef.current[index] !== "" ? (boardRef.current[index] === "X" ? "🍩" : "🍭") : ""}
              </div>
            )
          )}
        </div>
        {isYourTurn ? (
          <div className="turnState">Play, choose your cell</div>
        ) : (
          <div className="turnState">Wait for your turn</div>
        )}
      </div>
    ) : null;

  const GameOverPlayerDisplay = () => (
    <div className="game-over">
      Game Over!
      <br />
      Want replay?
      <br />
      Yass! , Tell Admin to restart the game!
      <br />
      No? , it's o.k. : <Button onClick={() => exitGame()}>No</Button>
    </div>
  );

  const GameOverAdminDisplay = () => (
    <div className="game-over">
      Game Over!
      <br />
      Want replay?
      <br />
      <br />
      <Button variant="contained" onClick={() => restartGame()}>
        Yass!
      </Button>{" "}
      <Button onClick={() => exitGame(admin)}>No</Button>
    </div>
  );

  const WaitingDisplay = () => (
    <div className="waiting">
      <CircularProgress size={50} />
      <br />
      <h4>Get Ready!</h4>
    </div>
  );

  // Main Render
  return (
    <div className="tictactoe">
      <div className="tictactoe-container">
        {!isGameOver ? (
          boardData ? (
            <BoardDisplay />
          ) : (
            <WaitingDisplay />
          )
        ) : admin ? (
          <GameOverAdminDisplay />
        ) : (
          <GameOverPlayerDisplay />
        )}
      </div>
    </div>
  );
});

export default TicTacToeRemote;
