// hooks/useQuestions.ts
import { useState, useEffect } from "react";
import { Question } from "types/triviaGameTypes";
interface useQuestionsProps  {
  questionsCount: number;
  reload_index: number;
};

const useQuestions = ({questionsCount, reload_index = 0}:useQuestionsProps) => {
  const [questions, setQuestions] = useState<Question[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchQuestions() {
      try {
        const response = await fetch(
          `https://antibug.co.il/api/quizzes/questions/random?questions_count=${questionsCount}`
        );
        if (!response.ok) throw new Error(`Error: ${response.status}`);
        const data = await response.json();
        setQuestions(data);
      } catch (err) {
        console.error("Failed to fetch questions", err);
      } finally {
        setLoading(false);
      }
    }

    fetchQuestions();
  }, [questionsCount, reload_index]);

  return { questions, loading };
};

export default useQuestions;
