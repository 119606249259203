// services/playerManager.ts
export interface Player {
  id: string;
  name: string;
  connected: boolean;
  emoji: string;
  score: number;
  win?: number;
}

class PlayerManager {
  private players: Player[] = []; // רשימת המשתמשים
  private currectTurn = -1;
  private playersRandTurn: Player[] = null;

  // החזרת כל המשתמשים
  getPlayers() {
    return this.players;
  }

  getPlayer(playerID: string) {
    return this.players.find((player) => player.id === playerID);
  }

  count() {
    return this.players.length;
  }

  // בדיקת אם משתמש כבר קיים
  playerExists(playerId: string): boolean {
    return this.players.some((player) => player.id === playerId);
  }

  resetPoints() {
    this.players.forEach((player) => (player.score = 0));
  }

  // הוספת משתמש חדש
  addPlayer(player: Player) {
    if (!this.playerExists(player.id)) {
      this.players.push(player);
    }
  }

  // הסרת משתמש
  removePlayer(playerId: string) {
    this.players = this.players.filter((player) => player.id !== playerId);
  }

  // getRandomPlayer() {
  //   const randomIndex = Math.floor(Math.random() * this.players.length);
  //   return this.players[randomIndex];
  // }

  setRandonTurns() {
    this.playersRandTurn = this.players.sort(() => Math.random() - 0.5);
  }
  getCurrentPlayerTurn() {
    this.currectTurn = (this.currectTurn + 1) % this.players.length;
    return this.playersRandTurn[this.currectTurn];
  }

  // ניקוי כל המשתמשים
  clearPlayers() {
    this.players = [];
  }
}

// יצירת Singleton
const playerManager = new PlayerManager();
export default playerManager;
