import React from "react";
import { Dialog, DialogTitle, DialogContent } from "@mui/material";

interface EmojiSelectorProps {
  emojis: string[];
  open: boolean;
  onClose: () => void;
  onSelect: (emoji: string) => void;
}

const AvatarSelector = ({ emojis, open, onClose, onSelect }:EmojiSelectorProps):React.JSX.Element =>  {
  return (
    <Dialog fullScreen open={open} onClose={onClose} aria-labelledby="responsive-dialog-title">
      <DialogTitle className="emoji-dialog-title">
        Select Your Avatar:
        <button className="emoji-close-button" onClick={onClose}>
          ❌
        </button>
      </DialogTitle>
      <DialogContent dividers>
        <div className="emoji-selctor-container">
          {emojis.map((emoji) => (
            <button key={emoji} onClick={() => onSelect(emoji)}>
              {emoji}
            </button>
          ))}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AvatarSelector;
