// services/EMQXBoardManager.ts
import mqtt, { MqttClient } from "mqtt";

class EMQXManager {
  private client: MqttClient | null = null;
  private boardId: string;
  private playerID?: string | null;
  private handleMessage: (topic: string, message: string) => void;

  constructor(boardId: string, handleMessage: (topic: string, message: string) => void, playerID: string = null) {
    this.boardId = boardId;
    this.playerID = playerID;

    this.handleMessage = handleMessage;
  }

  // יצירת החיבור ל-EMQX
  connect() {
    const clientId = `game_client_${Math.random().toString(16).substring(2, 8)}`; //todo
    const mqttUrl = "wss://antibug.co.il/mqtt"; // כתובת החיבור שלך ל-EMQX

    let topics = [];
    if (this.playerID) {
      topics = [`game_board/${this.boardId}/all`, `game_board/${this.boardId}/${this.playerID}`];
    } else {
      topics = [`game_board/${this.boardId}/admin`];
    }

    this.client = mqtt.connect(mqttUrl, {
      clientId,
      will: {
        topic: "status/clients",
        payload: "Disconnected unexpectedly",
        qos: 1,
        retain: false,
      },
    });

    this.client.on("connect", () => {
      console.log("Connected to EMQX");

      // נרשמים לנושאים הקשורים ללוח המשחק
      topics.forEach((topic) => {
        this.client?.subscribe(topic, (err) => {
          if (err) {
            console.log("Error subscribing to game topic:", err);
          } else {
            console.log(`Subscribed to : ${topic}`);
          }
        });
      });
    });

    this.client.on("message", (topic, message) => {
      this.handleMessage(topic, message.toString());
    });

    this.client.on("error", (error) => {
      console.error("MQTT Error:", error);
    });
  }

  // פרסום הודעות ל-EMQX
  publish(topic: string, message: any) {
    if (this.client) {
      this.client.publish(topic, JSON.stringify(message), (err) => {
        if (err) {
          console.error("Error publishing message:", err);
        }
      });
    }
  }

  // סגירת החיבור כאשר לא צריך יותר
  disconnect() {
    if (this.client) {
      this.client.end();
      console.log("Disconnected from EMQX");
    }
  }
}

let emqxManager: EMQXManager | null = null;

export const getEMQXManager = (
  boardId: string,
  handleMessage: (topic: string, message: string) => void,
  playerID: string = null
): EMQXManager => {
  if (!emqxManager) {
    emqxManager = new EMQXManager(boardId, handleMessage, playerID);
  }
  return emqxManager;
};
