import React, { useState, useEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TriviaRemote from "../components/games/Trivia/client/TriviaRemote";
import TicTacToeRemote from "../components/games/TicTacToe/client/ticTacToe_player";
import {GAMES_TYPES, MESSAGE_TYPES, GAME_MESSAGE_TYPES, GAME_STATE_TYPES } from "types/gameTypes";
import PlayerBoardForm from "components/playerBoard/PlayerBoard";
import { getEMQXManager } from "../services/EMQXBoardManager"; // חיבור הגלובלי

const _fastapi_server = "https://antibug.co.il/api/";
const _token_url = _fastapi_server + "/users/token";

const PlayerController = (): React.JSX.Element => {
  const playerIdRef = useRef<string | null>(null);
  const loginRef = useRef<boolean>(false);

  const [boardId, setBoardId] = useState<string>("");
  const [playerId, setPlayerId] = useState<string>("");
  const [login, setLogin] = useState<boolean>(false);
  const [admin, setAdmin] = useState<boolean>(false);
  const [gameState, setGameState] = useState<string>(GAME_STATE_TYPES.LOGIN);
  const [connected, setConnected] = useState<boolean>(false);
  const [token, setToken] = useState<string>("");
  // const [gameMsg, setGameMsg] = useState<any>(null);

  const [gameType, setGameType] = useState<GAMES_TYPES>(null);

  const emqxManagerRef = useRef<any>(null); // שימוש בחיבור הגלובלי
  const gameMessagesRef = useRef<{ gameMessages: (message: Record<string, unknown>) => void }>(null);

  const handleIncomingMessage = (topic: string, message: string): void => {
    const clientId = playerIdRef.current;

    const msg = JSON.parse(message.toString());

    if (msg.type === MESSAGE_TYPES.GAME) {
      if (loginRef.current && msg.state === GAME_STATE_TYPES.STARTING) {
        // toast(`🎉 Game Start!`);
        setGameState(GAME_STATE_TYPES.STARTING);
        return;
      } else if (msg.gameData != null) {
        const _gameMsg = msg.gameData;
        if (msg.gameType === GAME_STATE_TYPES.STARTING) {
        }
        gameMessagesRef?.current?.gameMessages(_gameMsg);
      } else {
        setGameState(msg.state);
      }
    }

    if (topic.endsWith(clientId || "")) {
      if (msg.type === MESSAGE_TYPES.JOIN) {
        if (msg.admin !== undefined && msg.admin) {
          setLogin(true);
          // toast(`👑 Login as admin!`);
          setAdmin(true);
        }

        if (msg.response === "denied") {
          toast(`🚫 Denied. ${msg.message}`);
          setGameState(GAME_STATE_TYPES.DENIED);
        } else if (msg.response === "accepted") {
          setLogin(true);
          // toast(`🫶 Login Success!`);
        }

        setGameType(msg.gameType);
      }
    }
  };

  const connectToBoard = async (_boardID: string, _userName: string, emoji: string): Promise<void> => {
    setBoardId(_boardID);

    // toast("Connecting to board...");
    const playerId = "emqx_react_player_" + Math.random().toString(16).substring(2, 8);
    setPlayerId(playerId);

    const manager = getEMQXManager(_boardID, handleIncomingMessage, playerId);
    await manager.connect();
    emqxManagerRef.current = manager;

    emqxManagerRef.current.publish(`game_board/${_boardID}/admin`, {
      type: MESSAGE_TYPES.JOIN,
      playerId: playerId,
      playerName: _userName,
      emoji: emoji,
    });

    setConnected(true);
  };

  useEffect(() => {
    loginRef.current = login;
  }, [login]);

  useEffect(() => {
    playerIdRef.current = playerId;
  }, [playerId]);

  useEffect(() => {
    if (!token) {
      var _token = localStorage.getItem("token");
      if (_token) {
        setToken(_token);
      } else {
        fetch(_token_url)
          .then((response) => response.json())
          .then((data) => {
            localStorage.setItem("Token", data.token);
            setToken(data.token);
          })
          .catch((error) => console.error(error));
      }
    }
  }, [token]);

  const sendMove = (move, type: GAME_MESSAGE_TYPES | MESSAGE_TYPES) => {
    if (connected) {
      const sendmsg = {
        playerId: playerId,
        type: type ?? GAME_MESSAGE_TYPES.MOVE,
        move,
      };

      emqxManagerRef.current?.publish(`game_board/${boardId}/admin`, sendmsg);
    }
  };

  const refreshPage = () => {
    //{/* //"javascript:window.location.href=window.location.href" */}
    return "window.location.reload();";
  };

  const whatsAppMsg = () => {
    return (
      "https://api.whatsapp.com/send?text='Join me to play YASS 🤩\r\n https://antibug.co.il/player?boardID=" +
      boardId +
      "' data-action='share/whatsapp/share'"
    );
  };

  const Game = () => {
    if (gameType === GAMES_TYPES.TRIVIA) {
       return <TriviaRemote ref={gameMessagesRef} timerInterval={20} sendMove={sendMove} admin={admin} />;
    } else if (gameType === GAMES_TYPES.TICTACTOE) {
      return <TicTacToeRemote  ref={gameMessagesRef} sendMove={sendMove} admin={admin} />; //gameMessages={gameMsg}
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        height: "100vh",
      }}>
      <ToastContainer
        position="top-right"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
        theme="light"
      />

      <div>
        {!connected && gameState === GAME_STATE_TYPES.LOGIN ? (
          <PlayerBoardForm startConnect={connectToBoard} />
        ) : (
          <div>
            {gameState === GAME_STATE_TYPES.STARTING ? (
              <div>{<Game />}</div>
            ) : admin ? (
              <div>
                <span className="adminIcon">👑</span>
                <br />
                You are an admin!
                <br />
                <br />
                Please Waiting for another
                <br />
                player to start the game.
                <br />
                <br />
                <Button
                  className="px-4 py-2"
                  variant="contained"
                  color="secondary"
                  onClick={() => sendMove(GAME_STATE_TYPES.STARTING, MESSAGE_TYPES.GAME)}>
                  Start Game!
                </Button>
              </div>
            ) : (
              <div>
                <div>
                  You are connected!
                  <br />
                  Please wait for start.
                  <br />
                  Share to friends: <a href={whatsAppMsg()}>WhatsApp</a>
                </div>
              </div>
            )}
            {gameState === GAME_STATE_TYPES.DENIED && (
              <div>
                You are not allowed to play!
                <br />
                <a href={refreshPage()}>Login screen</a>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default PlayerController;
