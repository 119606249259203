import React, { useEffect, useRef, useState } from "react";
import { TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useSearchParams } from "react-router-dom";
import PlayerAvatar from "components/avatars/Avatars";

interface GameBoardFormProps {
  startConnect: (boardID: string, userName: string, emoji: string) => void;
}

const PlayerBoardForm = ({ startConnect }: GameBoardFormProps): React.JSX.Element => {
  const [queryParameters] = useSearchParams();

  const [boardId, setBoardId] = useState("");
  const [boardIdFormatted, setBoardIdFormatted] = useState("");
  const [userName, setUserName] = useState("");
  const [playerAvatar, setPlayerAvatar] = useState("");
  const [boardIdError, setBoardIdError] = useState(false);
  const [userNameError, setUserNameError] = useState(false);

  const [startConnecting, setStartConnecting] = useState(false);
  const boardIDFieldRef = useRef<HTMLInputElement>(null);
  const userNameFieldRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    var _boardID = queryParameters.get("boardID");
    if (_boardID && !isNaN(parseInt(_boardID))) {
      setBoardId(_boardID);
    }

    const _username = localStorage.getItem("username");
    if (_username) setUserName(_username);
  }, [queryParameters]);

  const formatBoardId = (value) => {
    // הסרת כל תו שאינו מספר
    const numericValue = value.replace(/\D/g, "");
    setBoardId(numericValue);

    // הגדרת פורמט בהתאם לאורך המספר
    if (numericValue.length > 6) {
      return `${numericValue.slice(0, 3)}-${numericValue.slice(3, 6)}-${numericValue.slice(6, 8)}`;
    } else if (numericValue.length > 3) {
      return `${numericValue.slice(0, 3)}-${numericValue.slice(3)}`;
    }
    return numericValue; // אם פחות מ-3 תווים, מחזיר כמות רגילה
  };

  const handleConnectButton = () => {
    if (!boardId || boardId.trim().replace(/\D/g, "") === "") {
      setBoardIdError(true);
      boardIDFieldRef?.current.focus();
      return;
    } else {
      if (boardIdError) setBoardIdError(false);
      localStorage.setItem("boardID", boardId);
    }

    if (!userName || userName.trim() === "") {
      setUserNameError(true);
      userNameFieldRef?.current.focus();
      return;
    } else {
      localStorage.setItem("username", userName);
    }

    setStartConnecting(true);
    startConnect(boardId, userName, playerAvatar);
  };

  return (
    <form autoComplete="off">
      <h1>Welcome to the Game!</h1>
      <PlayerAvatar setEmoji={setPlayerAvatar} />
      <div>
        <TextField
          style={{ marginBottom: "3vh" }}
          error={boardIdError}
          id="txtBoardID"
          label="Enter Board ID"
          variant="outlined"
          value={boardIdFormatted}
          inputRef={boardIDFieldRef}
          onChange={(e) => {
            setBoardIdFormatted(formatBoardId(e.target.value));
          }}
        />
        <br />
        <TextField
          id="txtPlayerName"
          style={{ marginBottom: "3vh" }}
          error={userNameError}
          label="Player Name"
          variant="outlined"
          value={userName}
          inputRef={userNameFieldRef}
          onChange={(e) => setUserName(e.target.value)}
        />
        <br />
      </div>
      <LoadingButton
        loading={startConnecting}
        variant="contained"
        color="primary"
        onClick={() => handleConnectButton()}>
        Connect
      </LoadingButton>
    </form>
  );
};

export default PlayerBoardForm;
