import React, { useEffect, useState } from "react";
import "./Leaderboard.css";
import playerManager, { Player } from "services/PlayersManager";

const topThreeEmojis = ["🥇", "🥈", "🥉"];
interface LeaderboardProps {
  exitGame: () => void
}

const Leaderboard = ({exitGame}:LeaderboardProps): React.JSX.Element => {
  // הנחת טיפוס החזרת השחקנים
  const players: Player[] = playerManager.getPlayers();
  const seconds: number = 30;
  const [secondsLeft, setSecondsLeft] = useState(seconds);

  // מיון השחקנים לפי ניקוד (מהגבוה לנמוך)
  const sortedPlayers = [...players].sort((a, b) => b.score - a.score);
  const topThree = sortedPlayers.slice(0, 3);
  const others = sortedPlayers.slice(3);

  useEffect(() => {
    if (secondsLeft <= 0) {
      if(exitGame) exitGame();
      return; // מפסיק את הפונקציה כאן
    }

    const interval = setInterval(() => {
      setSecondsLeft((prevSeconds) => prevSeconds - 1);
    }, 1000);

    // ניקוי ה-interval כאשר הרכיב מוסר מה-DOM
    return () => clearInterval(interval);
  }, [secondsLeft]);

  return (
    <div>
      <div className="leaderboard">
        {/* שלושת המקומות הראשונים */}
        <div>
          <div className="podium">
            {topThree.map((player, index) => (
              <div key={player.id} className={`podium-place place-${index + 1}`}>
                <div className="podium-rank">{topThreeEmojis[index]}</div>
                <div className="podium-emoji">{player.emoji}</div>
                <div className="podium-name">{player.name}</div>
                <div className="podium-score">{player.score ?? 0} pts</div>
              </div>
            ))}
          </div>
          {/* טבלת שאר המתמודדים */}
          {players.length > 3 && (
            <div className="others">
              <h3>Other Players</h3>
              {others.map((player, index) => (
                <div key={player.id} className="other-player">
                  <span className="other-rank">#{index + 4}</span>
                  <span className="other-name">{player.name}</span>
                  <span className="other-score">{player.score} pts</span>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <div style={{ flex: 5, width: "100%", height: "20px", backgroundColor: "ccc" }}>
        <div className="progress progress-striped active">
          <div
            id="timerReplay"
            role="progressbar"
            style={{ width: `${(secondsLeft / seconds) * 100}%`, backgroundColor: "#2196F3" }}
            className="progress-bar progress-bar-secondary"
          />
        </div>
      </div>
    </div>
  );
};

export default Leaderboard;
